import React from 'react'
import './AboutUs.css'
import AboutMain from '../assets/aboutus-mail.jpg'
import AboutWhyChoose from '../assets/why-choose.jpg'
import { motion } from 'framer-motion'


export default function AboutUs() {
 

  return (
    <>
      <div id='about-view'>
        <section className='who-we-are'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 twr-who-we-are-content'>
                <h1 ><span>TheWebRock</span> - Your Digital Marketing and Web Design/Development Partner</h1>

                  <p>At TheWebRock, we are a leading digital marketing and web design/development company committed to helping businesses thrive in the online world. With our expertise, creativity, and customer-centric approach, we deliver exceptional solutions tailored to meet your unique needs. As a team of passionate professionals, we are dedicated to your success and strive to exceed your expectations at every step.</p>

                  <motion.img src={ AboutMain } 
                     initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                          duration: 0.3,
                          ease: [0, 0.71, 0.2, 1.01],
                          scale: {
                          type: "spring",
                          damping: 5,
                          stiffness: 100,
                          restDelta: 0.001
                          }
                      }}
                  />

              </div>
            </div>
          </div>
        </section>

        <section className='why-choose-us'>
          <div className='container'> 
            <h2>Why Choose TheWebRock?</h2>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <img className='why-choose-img' src={ AboutWhyChoose } />
               </div>
              <div className='col-md-6 col-sm-12' >
                <ul className='list-unstyled'>

                  <li><span>Expertise and Innovation:</span> We are a team of highly skilled professionals with extensive knowledge and experience in digital marketing, web design, and development. We stay updated with the latest industry trends and employ innovative strategies to deliver exceptional results.</li>
                  <li><span>Customized Solutions:</span> We understand that every business is unique, and we tailor our solutions to meet your specific requirements. Whether you need a stunning website, a robust e-commerce platform, or a comprehensive digital marketing campaign, we create custom strategies that align with your goals.</li>
                  <li><span>Customer-Centric Approach:</span> At TheWebRock, our clients are at the heart of everything we do. We prioritize open communication, collaboration, and transparency throughout the project lifecycle. Your satisfaction is our ultimate goal, and we go above and beyond to deliver excellence.</li>
                  <li><span>Results-Driven Approach:</span> We are committed to delivering tangible results that make a significant impact on your business. Whether it's increasing website traffic, improving conversions, or enhancing brand visibility, we focus on achieving measurable outcomes that drive success.</li>
                  <li><span>Long-Term Partnerships:</span> We believe in building enduring relationships with our clients. We aim to be your trusted partner for all your digital marketing and web design/development needs, providing ongoing support, maintenance, and optimization to ensure your continued success.</li>
                  
                </ul>
              </div>
            </div>

          </div>
        </section>

        <section className='vision-mission'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-sm-6'>
                <h2>Our Mission</h2>
                <p>Our mission at TheWebRock is to empower businesses by providing top-notch digital marketing and web design/development services. We aim to help our clients establish a strong online presence, connect with their target audience, and achieve their business goals. We are driven by a passion for innovation, continuous learning, and delivering tangible results that make a significant impact on our clients' success. Through our expertise and dedication, we aim to be a reliable partner that contributes to the growth and prosperity of our clients' businesses.</p>
              </div>
              <div className='col-md-6 col-sm-6'>
                <h2>Our Vision</h2>
                <p>At TheWebRock, our vision is to be a trusted and renowned digital marketing and web design/development company globally. We aspire to be recognized for our exceptional creativity, cutting-edge solutions, and unwavering commitment to client satisfaction. We envision ourselves as industry leaders, consistently staying ahead of the curve by embracing the latest technologies, trends, and best practices. Through our vision, we aim to foster long-term partnerships with our clients, helping them achieve sustainable growth and maximize their online potential.</p>
              </div>
            </div>
          </div>
        </section>

      </div>
    </>
  )
}
