import React from 'react'
import '../components/Footer.css'
import { Link } from 'react-router-dom'
import logoIcon from '../assets/footer-logo.png'
import { motion } from 'framer-motion'

export default function Footer() {
  let year = new Date().getFullYear();

 

  return (
    
    <>
        
          <footer className="footer-20192">
            <div className="site-section">
              <div className="container">

                <motion.div className="cta d-block d-md-flex align-items-center px-5"
                    whileHover={{ scale: 1.1 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                       
                >
                  <div>
                    <h2 className="mb-0">Ready for a next project?</h2>
                    <h3 className="text-dark">Let's get started!</h3>
                  </div>
                  <div className="ms-auto">
                    <Link to='/contact' className="btn btn-dark rounded-1 py-3 px-5">Contact us</Link>
                  </div>
                </motion.div>
                <div className="row">

                  <div className="col-sm">
                    <Link to='/' className="footer-logo">
                    <motion.img 
                      style={{ width: '277px' }} 
                      src={ logoIcon } 
                      animate={{
                        scale: [1, 2, 2, 1, 1],
                        rotate: [0, 0, 180, 180, 0],
                        borderRadius: ["0%", "0%", "50%", "50%", "0%"]
                      }}
                      transition={{
                        duration: 2,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        repeatDelay: 1
                      }}
                      />
                    </Link>
                    
                  </div>
                  <div className="col-sm">
                    <h3>Services</h3>
                    <ul className="list-unstyled links">
                      <li><a href="#">Web Develpment</a></li>
                      <li><a href="#">Web Designing</a></li>
                      <li><a href="#">Digital Marketing</a></li>
                      <li><a href="#">SEO</a></li>
                      <li><a href="#">Graphic Designing</a></li>
                      <li><a href="#">Content Writing</a></li>
                    </ul>
                  </div>
                  <div className="col-md-3 twr-footer-contact">
                    <h3>Contact Us</h3>
                    <ul className='list-unstyled'>
                      <li><i class="fa fa-envelope" aria-hidden="true"></i> contact@thewebrock.com</li>
                      <li><i class="fa fa-phone" aria-hidden="true"></i> +91-87279-37447</li>
                    </ul>

                    <h3>Follow us</h3>
                    <ul className="list-unstyled social">
                      <li><a href="#"><i className="bi bi-facebook"></i></a></li>
                      <li><a href="#"><i className="bi bi-instagram"></i></a></li>
                      <li><a href="#"><i className="bbi bi-linkedin"></i></a></li>
                      <li><a href="#"><i className="bi bi-google"></i></a></li>
                      <li><a href="#"><i className="bi-skype"></i></a></li>
                    </ul>
                  </div>
                  
                </div>
                <div className='row twr-footer-copy-right'>
                    <div className='col-md-12 col-sm-12'>
                      <p className="copyright">
                        <small>&copy; {year} TheWebRock</small>
                      </p>
                    </div>
                </div>
              </div>
            </div>
          </footer>
        
    </>
  )
}
