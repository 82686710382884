import React from 'react'
import './Services.css'
import servicesBanner from '../assets/service-banner.jpg'
import webDev from '../assets/web-development.png'
import webDeg from '../assets/web-design.png'
import digiMarket from '../assets/digital-marketing.png'
import graphicDeg from '../assets/graphic-designing.png'
import seo from '../assets/seo.png'
import content from '../assets/content.png'
import { motion } from 'framer-motion'


export default function Services() {
  return (
    <>
        <div id='services-view'>
            <section className='who-we-are'>
                <div className='container'>
                    <div className='row'>
                    <div className='col-md-12 twr-who-we-are-content'>
                        <h1 ><span>TheWebRock</span> - Services</h1>

                        <p>Thewebrock is your one-stop destination for unlocking the full potential of your online presence. With a comprehensive range of services, including digital marketing, web development, and design, we empower businesses to thrive in the digital landscape. Our team of experts leverages cutting-edge strategies and innovative techniques to drive targeted traffic, create engaging websites, and craft visually stunning designs. Partner with Thewebrock and elevate your brand's online success to new heights.</p>

                        <motion.img src={ servicesBanner } 
                             initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                                duration: 0.3,
                                ease: [0, 0.71, 0.2, 1.01],
                                scale: {
                                type: "spring",
                                damping: 5,
                                stiffness: 100,
                                restDelta: 0.001
                                }
                            }}
                        />

                    </div>
                    </div>
                </div>
            </section>

            <section className='services-archive'>
                <div className='container'>
                    <h2>Unlock Your Online Potential with Thewebrock</h2>
                    <div className='row'>
                        <div className='col-md-4 col-sm-12'>
                        <motion.div class="card" 
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 400, damping: 10 }}
                        >
                            <img src={ webDev } class="card-img-top" style={{ background: '#F3E9D9' }} alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Web Development</h5>
                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                <a href="#" class="btn btn-primary twr-btn">Learn More..</a>
                            </div>
                        </motion.div>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                        <motion.div class="card" 
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 400, damping: 10 }}
                        >
                                <img src={ webDeg } class="card-img-top" style={{ background: '#CFE9E7' }} alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Web Desiging</h5>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="btn btn-primary twr-btn">Learn More..</a>
                                </div>
                            </motion.div>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                        <motion.div class="card" 
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 400, damping: 10 }}
                        >
                                <img src={ digiMarket } class="card-img-top" style={{ background: '#F5F0D4' }} alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Digital Marketing</h5>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="btn btn-primary twr-btn">Learn More..</a>
                                </div>
                            </motion.div>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                        <motion.div class="card" 
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 400, damping: 10 }}
                        >
                                <img src={ graphicDeg } class="card-img-top" style={{ background: '#E6DFF2' }} alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Graphic designing</h5>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="btn btn-primary twr-btn">Learn More..</a>
                                </div>
                            </motion.div>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                        <motion.div class="card" 
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 400, damping: 10 }}
                        >
                                <img src={ seo } class="card-img-top" style={{ background: '#F1DFDE' }} alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">SEO</h5>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="btn btn-primary twr-btn">Learn More..</a>
                                </div>
                            </motion.div>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                        <motion.div class="card" 
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 400, damping: 10 }}
                        >
                                <img src={ content } class="card-img-top" style={{ background: '#D2E9F2' }} alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Content Wrinting</h5>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="btn btn-primary twr-btn">Learn More..</a>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
  )
}
