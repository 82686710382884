import React from 'react'
import './Home.css'
import img1 from '../assets/img1.jpg'
import heroVector from '../assets/hero-vector.png'
import webDev from '../assets/web-development.png'
import webDeg from '../assets/web-design.png'
import digiMarket from '../assets/digital-marketing.png'
import graphicDeg from '../assets/graphic-designing.png'
import seo from '../assets/seo.png'
import content from '../assets/content.png'
import about1 from '../assets/about-1.jpg'
import about2 from '../assets/about-2.jpg'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'


export default function Home() {
  return (
    <div id='home-view'>
        <div className='container'>
            <motion.div 
                className="card twr-bg-card" 
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.3,
                    ease: [0, 0.71, 0.2, 1.01],
                    scale: {
                    type: "spring",
                    damping: 5,
                    stiffness: 100,
                    restDelta: 0.001
                    }
                }}
                >
                <div className="card-body">
                    <h1 className='twr-hero-title'>Empowering your <span>brand's</span> digital success, <span>creatively</span></h1>
                    <div className='row twr-hero-blocks'>
                        <div className='col-md-6 col-sm-6'>
                            <img className='hero-image' src={ heroVector } />
                        </div>
                        <div className='col-md-6 col-sm-6'>
                           <div className='hero-list'>
                                <ul>
                                    <li>Discovery and Planning</li>
                                    <li>Website Design and Development</li>
                                    <li>Search Engine Optimization (SEO)</li>
                                    <li>Content Creation and Marketing</li>
                                    <li>Social Media Marketing</li>
                                    <li>Paid Advertising (PPC)</li>
                                    <li>Analytics and Reporting</li>
                                    <li>Continuous Optimization and Growth</li>
                                </ul>
                           </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>

        <div className='container'>
            <div className='twr-services'>
                <h2>Fueling Growth, Amplifying Results</h2>

                <div className='row services-first-row'>
                    <div className='col-md-4 col-sm-6'>
                        <Link to='/about-us'>
                            <motion.div 
                                className="card mb-3"
                                whileHover={{ scale: 1.1 }}
                                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                                >
                                <div className="row g-0">
                                    <div className="col-md-4 col services-image">
                                        <img src={ webDev } className="img-fluid color-1" alt="..." />
                                    </div>
                                    <div className="col-md-8 col">
                                        <div className="card-body">
                                            <h5 className="card-title ">Web Development</h5>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </Link>
                    </div>
                    <div className='col-md-4 col-sm-6'>
                        <Link to='/about-us'>
                            <motion.div 
                                className="card mb-3"
                                whileHover={{ scale: 1.1 }}
                                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                                >
                                <div className="row g-0">
                                    <div className="col-md-4 col services-image">
                                        <img src={ webDeg } className="img-fluid color-2" alt="..." />
                                    </div>
                                    <div className="col-md-8 col ">
                                        <div className="card-body">
                                            <h5 className="card-title ">Web Designing</h5>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </Link>
                    </div>
                    <div className='col-md-4 col-sm-6'>
                        <Link to='/about-us'>
                            <motion.div 
                                className="card mb-3"
                                whileHover={{ scale: 1.1 }}
                                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                                >
                                <div className="row g-0">
                                    <div className="col-md-4 col services-image">
                                        <img src={ digiMarket } className="img-fluid color-3" alt="..." />
                                    </div>
                                    <div className="col-md-8 col ">
                                        <div className="card-body">
                                            <h5 className="card-title ">Digital Marketing</h5>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </Link>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-4 col-sm-6'>
                        <Link to='/about-us'>
                            <motion.div 
                                className="card mb-3"
                                whileHover={{ scale: 1.1 }}
                                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                                >
                                <div className="row g-0">
                                    <div className="col-md-4 col services-image">
                                        <img src={ graphicDeg } className="img-fluid color-4" alt="..." />
                                    </div>
                                    <div className="col-md-8 col ">
                                        <div className="card-body">
                                            <h5 className="card-title ">SEO</h5>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </Link>
                    </div>
                    <div className='col-md-4 col-sm-6'>
                        <Link to='/about-us'>
                            <motion.div 
                                className="card mb-3"
                                whileHover={{ scale: 1.1 }}
                                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                                >
                                <div className="row g-0">
                                    <div className="col-md-4 col services-image">
                                        <img src={ seo } className="img-fluid color-5" alt="..." />
                                    </div>
                                    <div className="col-md-8 col ">
                                        <div className="card-body">
                                            <h5 className="card-title ">Graphic Designing</h5>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </Link>
                    </div>
                    <div className='col-md-4 col-sm-6'>
                        <Link to='/about-us'>
                            <motion.div 
                                className="card mb-3"
                                whileHover={{ scale: 1.1 }}
                                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                                >
                                <div className="row g-0">
                                    <div className="col-md-4 col services-image">
                                        <img src={ content } className="img-fluid color-6" alt="..." />
                                    </div>
                                    <div className="col-md-8 col ">
                                        <div className="card-body">
                                            <h5 className="card-title ">Content Wrinting</h5>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </Link>
                    </div>
                </div>

            </div>
        </div>

        <div className='container'>
            <div className='twr-about'>
                <div className='row'>
                    <div className='col-md-3'>
                        <motion.img src={ about1 } 
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                        />
                    </div>
                    <div className='col-md-6 twr-about-content'>
                        <h2>Elevating Your Online Presence</h2>
                        <p>We are a dynamic digital marketing and web development company, dedicated to helping businesses thrive online. With our expertise in crafting impactful digital strategies and creating stunning websites, we empower brands to reach their full potential. Our passionate team combines innovation, creativity, and technical proficiency to deliver exceptional results that drive growth and success.</p>
                        <motion.button 
                            className='btn btn-primary twr-btn'
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            >
                            Learn More...
                        </motion.button>
                    </div>
                    <div className='col-md-3'>
                        <motion.img src={ about2 } 
                             whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                        />
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}
