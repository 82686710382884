import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import AboutUs from './pages/AboutUs';
import Home from './pages/Home';
import Services from './pages/Services';
import Contact from './pages/Contact';
import { useRef } from 'react';


function App() {

  const Layout = () => {
    return(
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    )
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route path='/' element={<Home />} />
            <Route path='about-us' element={<AboutUs />} />
            <Route path='services' element={<Services />} />
            <Route path='contact' element={<Contact />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
