import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import logo from '../assets/header-logo.png'
import { motion } from 'framer-motion'


export default function Header() {
  
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light " style={{ backgroundColor: '#CFE9E7' }}>
        <div className="container-fluid">
          <Link className="navbar-brand" to='/'>
            <motion.img
               className='header-logo' 
               src={ logo } 
               
               initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.2,
                  ease: [0, 0.71, 0.2, 1.01],
                  scale: {
                    type: "spring",
                    damping: 2,
                    stiffness: 200,
                    restDelta: 0.001
                  }
                }}
               />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
              <li className="nav-item">
                <NavLink className="nav-link twr-nav-link" to='/'>Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link twr-nav-link" to='/about-us'>About us</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link twr-nav-link" to='services'>Our Services</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link twr-nav-link" to='contact'>Contact Us</NavLink>
              </li>
              
            </ul>
            
          </div>
        </div>
      </nav>
    </>
  )
}
