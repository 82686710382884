import React, { useState } from 'react'
import './Contact.css'
import contactMain from '../assets/contact.png'
import { motion } from 'framer-motion'

export default function Contact() {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const contactSubmit = (e) => {
        e.preventDefault();

        console.log(name, email, phone, message)
        setName("")
        setEmail("")
        setPhone("")
        setMessage("")
  }

  return (
    <>
      <div id='contact-view'>
        <section className='who-we-are'>
            <div className='container'>
                <div className='row'>
                <div className='col-md-12 twr-who-we-are-content'>
                    <h1 ><span>TheWebRock</span> - Customer satisfaction is our priority. Contact us now</h1>

                    <motion.img src={ contactMain } 
                       initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 0.3,
                            ease: [0, 0.71, 0.2, 1.01],
                            scale: {
                            type: "spring",
                            damping: 5,
                            stiffness: 100,
                            restDelta: 0.001
                            }
                        }}
                    />

                </div>
                </div>
            </div>
        </section>
        <section className='contact-area'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <h2 className='contact-title'>Let's Discuss On Somthing <span>Cool</span> Togather</h2>
                <ul className='contact-info'>
                    <li> <i className="fa fa-envelope" aria-hidden="true"></i> contact@thewebrock.com</li>
                    <li> <i className="fa fa-phone" aria-hidden="true"></i> +91-87279-37447</li>
                </ul>
              </div>
              <div className='col-md-6 col-sm-12'>
                <div className='contact-form'>
                    <form onSubmit={ contactSubmit }>
                      <div className="form-group">
                          <input 
                              type="text" 
                              className="form-control" 
                              placeholder="Name" 
                              value={ name }
                              onChange={ (e) => setName(e.target.value) }
                          />
                      </div>
                      <div className="form-group">
                          <input 
                              type="email" 
                              className="form-control" 
                              placeholder="Email" 
                              value={ email }
                              onChange={ (e) => setEmail(e.target.value) }
                          />
                      </div>
                      <div className="form-group">
                        <input 
                            type="tel" 
                            className="form-control" 
                            placeholder="Phone" 
                            value={ phone }
                            onChange={ (e) => setPhone(e.target.value) }
                        />
                      </div>
                      <div className="form-group">
                        <textarea 
                            className="form-control" 
                            placeholder="Message" 
                            value={ message }
                            onChange={ (e) => setMessage(e.target.value) }
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <button type='submit' className='btn btn-primary twr-btn'><i class="fa fa-paper-plane" aria-hidden="true"></i> Send Message</button>
                      </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
